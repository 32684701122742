<template>
  <div id="create-hospital">
    <form-template 
      v-bind="config"
      @submit="submitCreate"
    />
  </div>
</template>

<script>
// mixins
import crudRequestMixin from '@/mixins/listPages/httpRequestMixins';

// components
import FormTemplate from '@/components/templates/FormTemplate';

export default {
  name: 'HospitalForm',
  mixins: [
    crudRequestMixin
  ],
  components: {
    FormTemplate,
  },
  data: () => ({
    config: {
      title: 'Add New Hospital',
      javaClass: "ch.galliard.pompe.api.dto.pages.Hospital",
      formLoading: false,
      form: [
        {
          name: 'name',
          label: 'Name',
          component: 'text-field',
          type: 'text',
          placeholder: 'Enter Hospital Name',
          mandatory: true,
        },
        {
          name: 'address',
          label: 'Address',
          component: 'text-area',
          type: 'textarea',
          placeholder: 'Enter Hospital Address'
        },
        {
          name: 'city',
          label: 'City',
          component: 'text-field',
          type: 'text',
          placeholder: 'Enter Hospital City',
          mandatory: true,
        },
        {
          name: 'postcode',
          label: 'Postcode',
          component: 'text-field',
          type: 'number',
          placeholder: 'Enter Hospital Postcode',
          mandatory: true,
        },
      ],
      formEdit: {},
      errors: {},
      backButtonDestination: '/web/summary/hospitals'
    },
  }),
  computed: {
    isEdit() {
      return this.$route.path.includes('edit');
    },
  },
  mounted() {
    if (this.isEdit) this.populateForm();
  }
}
</script>

<style>

</style>